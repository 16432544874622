import React from "react";
import { Box, Heading, Text, Spinner, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import IPageCard from "Core/types/IPageCard";
import getIdFromReportUrl from "Core/helpers/getIdFromReportUrl";
import moment from "moment";
// import api from 'Core/services/api';

const PageCard: React.FC<IPageCard> = function ({ test }) {
  // const [screenshot, setScreenshot] = useState<any>();

  // useEffect(() => {
  //   async function getData(): Promise<void> {
  //     if (test.links) {
  //       const reportId = getIdFromReportUrl(test.links.report);
  //       try {
  //         const { data } = await api.get(
  // `/performance-report/reports/${reportId}/resources/screenshot.jpg`
  //   );
  //         setScreenshot(data);
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   }
  //   getData();
  // }, [test.id]);

  return test.attributes.finished ? (
    <Link
      to={`/test/${test.links ? getIdFromReportUrl(test.links.report) : ""}`}
    >
      <Box
        borderRadius="20px"
        pt="56.25%"
        bgColor="gray.50"
        cursor="pointer"
        transition="0.3s"
        _hover={{
          opacity: 0.7
        }}
      />
      <Box pt="24px">
        <Heading as="h3" fontSize="21px" mb="4px">
          {test.id}
        </Heading>
        <Text fontSize="15px">
          Last update{" "}
          {moment.unix(test.attributes.created).format("DD/MM [at] HH:mm")}
        </Text>
      </Box>
    </Link>
  ) : (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      minH="305px"
    >
      <Spinner
        thickness="3px"
        speed="0.65s"
        emptyColor="gray.200"
        color="red.500"
        size="lg"
        mb="8px"
      />
      <Text>Running test</Text>
    </Flex>
  );
};

export default PageCard;
