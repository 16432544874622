interface IHeader {
  title: string,
  subtitle?: string
}

export const DEFAULT_VALUES = {
  title: '',
  subtitle: ''
};

export default IHeader;
