import React from 'react';

import {
  IconButton, Image
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface SidebarIconButtonProps {
  label: string,
  icon: string,
  link: string
}

const SidebarIconButton: React.FC<SidebarIconButtonProps> = function ({
  label,
  icon,
  link
}) {
  return (
    <Link to={link}>
      <IconButton
        aria-label={label}
        icon={<Image src={icon} width="25px" />}
        bgColor="#2B2B2B"
        borderRadius="12px"
        w="50px"
        h="50px"
        _hover={{
          bgColor: '#767676'
        }}
      />
    </Link>
  );
};

export default SidebarIconButton;
