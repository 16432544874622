import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  Text,
  Grid,
  HStack,
  Stack,
  useToast,
  Spinner,
  Tooltip,
  IconButton,
  Image
} from "@chakra-ui/react";
import TestHeader from "Components/layout/TestHeader";
import { useParams } from "react-router-dom";
import api from "Core/services/api";
import IReport from "Core/types/IReport";
import ILighthouse from "Core/types/ILighthouse";
import Card from "Components/elements/Card";
import NumberBlock from "Components/elements/NumberBlock";
import ResourcesPieSize from "Components/elements/ResourcesSizePie";
import DashboardTemplate from "Components/templates/Dashboard";
import Metric from "Components/elements/Metric";
import { FaQuestionCircle } from "react-icons/fa";
import BrowserTimings from "Components/elements/BrowserTimings";

import SpeedTimeSVG from "Assets/SpeedTimeSVG.svg";
import SpeedGaugeSVG from "Assets/SpeedGaugeSVG.svg";
import CircleGaugeSVG from "Assets/CircleGaugeSVG.svg";

const Test: React.FC = function () {
  const { id } = useParams();

  const toast = useToast();

  const [report, setReport] = useState<IReport>();
  const [lighthouse, setLighthouse] = useState<ILighthouse>();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (id) setTitle(id);
  }, [id]);

  useEffect(() => {
    if (lighthouse) setTitle(lighthouse.requestedUrl);
  }, [lighthouse]);

  const getData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { data } = await api.get(`/performance-report/reports/${id}`);

      setReport(data.data);

      const lighthouseRes = await api.get(
        `/performance-report/reports/${id}/resources/lighthouse.json`
      );
      setLighthouse(lighthouseRes.data);
      setLoading(false);
    } catch (e) {
      toast({
        title: "Ops.",
        description: "Something wrong happened.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setLoading(false);
    }
  }, [toast, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const gradeColor = (grade: string): string => {
    switch (grade) {
      case "A":
        return "green.500";
        break;
      case "B":
        return "green.300";
        break;
      case "C":
        return "yellow.500";
        break;
      default:
        return "red.500";
    }
  };

  return (
    <DashboardTemplate>
      <TestHeader title={title} />

      <HStack justifyContent="space-between" mb="24px">
        <Heading as="h2" size="lg">
          Report
        </Heading>
      </HStack>

      {loading ? (
        <Box textAlign="center" py="64px">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="red.500"
            size="xl"
          />
          <Text>Loading reports</Text>
        </Box>
      ) : (
        <Box>
          <Grid
            templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
            rowGap="32px"
            columnGap="24px"
            mb="24px"
          >
            <Metric
              title="GTMetrix Grade"
              help="This is your grade in the most famous website performance score in the web."
              description="You got the MAXIMUM SCORE Congratulations!"
              image={
                <Text
                  fontSize="100px"
                  lineHeight="1em"
                  fontWeight="bold"
                  color={gradeColor(report?.attributes.gtmetrix_grade || "")}
                >
                  {report?.attributes.gtmetrix_grade}
                </Text>
              }
            />
            <Metric
              title="Performance"
              help="Your Performance Score is essentially your Lighthouse Performance Score, as captured by GTmetrix, with our custom audits, Analysis Options, browser and hardware specifications."
              description="Woow! Your performance is  PERFECT!"
              value={report?.attributes.performance_score || 0}
            />
            <Metric
              title="Structure"
              help="It represents how well your page is built for optimal performance."
              description="Who built this?  Your structure is AMAZING!"
              value={report?.attributes.structure_score || 0}
              percentage
            />
            <Metric
              title="Largest Contentful Paint"
              help="LCP measures how long it takes for the largest content element (e.g. a hero image or heading text) on your page to become visible within your visitors' viewport. For a good user experience, aim for an LCP of 1.2 seconds or less."
              description="Lorem ipsum dolor sit amet, consetetur!"
              value={report?.attributes.largest_contentful_paint || 0}
              image={<Image w="95px" src={SpeedTimeSVG} />}
            />
            <Metric
              title="Total Blocking Time"
              help="TBT tells you how much time is blocked by scripts during your page loading process. For a good user experience, aim for a TBT of 150 milliseconds or less."
              description="Lorem ipsum dolor sit amet, consetetur!"
              value={report?.attributes.total_blocking_time || 0}
              image={<Image w="150px" src={SpeedGaugeSVG} />}
            />
            <Metric
              title="Cumulative Layout Shift"
              help="How much your page's layout shifts as it loads. A good user experience is a score of 0.1 or less."
              description="Lorem ipsum dolor sit amet, consetetur!"
              value={report?.attributes.cumulative_layout_shift || 0}
              image={<Image w="110px" src={CircleGaugeSVG} />}
            />
          </Grid>

          <Grid templateColumns={{ base: "1fr", lg: "0.8fr 1fr" }} gap="24px">
            <BrowserTimings
              items={[
                {
                  title: "First Contentful Paint",
                  help: "First Contentful Paint",
                  value: report?.attributes.first_contentful_paint || 0
                },
                {
                  title: "Speed index",
                  help: "Speed index",
                  value: report?.attributes.speed_index || 0
                },
                {
                  title: "Time to interactive",
                  help: "Time to interactive",
                  value: report?.attributes.time_to_interactive || 0
                }
              ]}
            />
            <Metric
              title="Total page size"
              help="How much your page's layout shifts as it loads. A good user experience is a score of 0.1 or less."
            >
              <Box />
            </Metric>
          </Grid>
        </Box>
      )}
    </DashboardTemplate>
  );
};

export default Test;
