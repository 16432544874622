const renderValue = (value: number, ms = false, percentage = false): number | string => {
  if (value !== undefined) {
    if (ms) return `${value}ms`;
    if (percentage) return `${value}%`;

    return `${(value / 1000).toFixed(1)}s`;
  }

  return 0;
};

export default renderValue;
