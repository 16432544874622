import React from 'react';
import {
  Box, Heading, Text, IconButton, HStack, Button, Image
} from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { BiRefresh } from 'react-icons/bi';
import IHeader, { DEFAULT_VALUES } from 'Core/types/IHeader';
import RedReport from 'Assets/RedReport.svg';

const TestHeader: React.FC<IHeader> = function ({ title, subtitle }) {
  const location = useLocation();
  return (
    <Box>
      <HStack spacing="24px" mb="16px">
        <Heading fontSize="35px">Report</Heading>
        <Image src={RedReport} />
      </HStack>
      <HStack spacing="24px" mb="40px">
        <HStack alignItems="flex-start">
          {
            location.pathname !== '/'
            && (
              <Link to="/">
                <IconButton
                  size="sm"
                  aria-label="back"
                  icon={<BsChevronLeft />}
                  variant="ghost"
                  mt="-5px"
                  _hover={{
                    bg: 'none',
                    color: 'white',
                    opacity: 0.7
                  }}
                />
              </Link>
            )
          }
          <Box>
            <Heading fontSize={{ base: '14px', lg: '25px' }}>{title}</Heading>
            {!!subtitle && <Text>{subtitle}</Text>}
          </Box>
        </HStack>

        <Button
          colorScheme="red"
          borderRadius="29px"
          px="16px"
          h="44px"
          leftIcon={<BiRefresh size="25px" />}
          isDisabled
          d={{ base: 'none', lg: 'flex' }}
        >
          Refresh
        </Button>
        <IconButton
          size="sm"
          d={{ base: 'flex', lg: 'none' }}
          aria-label="refresh"
          icon={<BiRefresh size="18px" />}
          colorScheme="red"
          borderRadius="100%"
        />
      </HStack>
    </Box>
  );
};

TestHeader.defaultProps = DEFAULT_VALUES;

export default TestHeader;
