import React from 'react';
import Sidebar from 'Components/layout/Sidebar';
import { Box } from '@chakra-ui/react';
import Bg from 'Assets/Bg.png';

const DashboardTemplate: React.FC = function ({ children }) {
  return (
    <Box
      h="100vh"
      bgColor="#0C0C0C"
      position="relative"
      color="white"
    >
      <Box
        position="fixed"
        left="0"
        top="0"
        bottom="0"
        right="0"
        bgImage={Bg}
        bgSize="cover"
        bgPos="center"
        opacity="0.7"
        zIndex="1"
      />
      <Sidebar />
      <Box
        position="relative"
        zIndex="10"
        ml={{
          base: '70px',
          lg: '100px'
        }}
        p={{
          base: '20px',
          lg: '72px'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardTemplate;
