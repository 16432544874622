import React from 'react';
import Logo from 'Assets/Logo.svg';
import HomeIcon from 'Assets/HomeIcon.svg';
import DashIcon from 'Assets/DashIcon.svg';

import {
  Stack, Image
} from '@chakra-ui/react';
import SidebarIconButton from 'Components/elements/SidebarIconButton';

const Sidebar: React.FC = function () {
  return (
    <Stack
      py="40px"
      alignItems="center"
      w={{
        base: '70px',
        lg: '100px'
      }}
      bgColor="#0C0C0C"
      position="fixed"
      left="0"
      bottom="0"
      top="0"
      zIndex="10"
      spacing="96px"
    >
      <Image src={Logo} />
      <Stack spacing="32px">
        <SidebarIconButton label="Home" icon={HomeIcon} link="/" />
        <SidebarIconButton label="Dashboard" icon={DashIcon} link="/" />
      </Stack>
    </Stack>
  );
};

export default Sidebar;
