import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import 'styles.css';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  fonts: {
    body: 'museo-sans, sans-serif',
    heading: 'museo-sans, sans-serif'
  },
  styles: {
    global: {
      body: {
        color: 'white',
        bgColor: '#0C0C0C'
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
