import React, { ReactElement } from 'react';
import {
  Box, Heading, Text, Tooltip, HStack, IconButton, Grid, Flex
} from '@chakra-ui/react';
import { FaQuestionCircle } from 'react-icons/fa';
import renderValue from 'Core/helpers/renderValue';

interface ICard {
  title: string,
  description?: string,
  value?: number,
  ms?: boolean,
  help?: string,
  percentage?: boolean,
  image?: ReactElement
}

const Metric: React.FC<ICard> = function ({
  title, description, help, value, ms, percentage, children, image
}) {
  return (
    <Box>
      <HStack mb="16px">
        <Heading
          as="h4"
          fontSize="20px"
          fontWeight="700"
        >
          {title}

        </Heading>
        {
          !!help
          && (
            <Tooltip
              bgColor="white"
              color="black"
              label={help}
              fontSize="12px"
              borderRadius="5px"
              p="12px"
            >
              <IconButton
                icon={<FaQuestionCircle size="13px" />}
                aria-label="faq"
                size="xs"
                variant="ghost"
                _hover={{
                  color: 'red'
                }}
              />
            </Tooltip>
          )
        }
      </HStack>
      <Box
        borderRadius="20px"
        bgColor="rgba(255,255,255,.1)"
        __css={{
          backdropFilter: 'blur(18px)'
        }}
        p="50px"
        color="white"
      >
        {
          children || (
            <Grid
              minH="150px"
              templateColumns={{ base: '1fr', lg: '0.75fr 1fr' }}
              alignItems="center"
              gap="24px"
            >
              <Flex justifyContent="center">
                {image}
              </Flex>
              <Box>
                {
                  value !== undefined
                  && (
                    <Text fontSize="58px" fontWeight="bold">
                      {renderValue(value, ms, percentage)}
                    </Text>
                  )
                }
                {
                  !!description
                  && <Text fontSize="18px" fontWeight="500">{description}</Text>
                }
              </Box>
            </Grid>
          )
        }
      </Box>
    </Box>
  );
};

Metric.defaultProps = {
  help: undefined,
  ms: false,
  percentage: false,
  value: undefined,
  description: undefined,
  image: undefined
};

export default Metric;
