import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Heading,
  Grid,
  HStack,
  Input,
  useToast,
  Spinner,
  Text,
  Image,
  Flex
} from "@chakra-ui/react";
import PageCard from "Components/elements/PageCard";
import api from "Core/services/api";
import ITest from "Core/types/ITest";
import DashboardTemplate from "Components/templates/Dashboard";
import Reports from "Assets/Reports.svg";
import { BsChevronRight } from "react-icons/bs";

const Home: React.FC = function () {
  const toast = useToast();

  const [url, setUrl] = useState<string>("");
  const [submiting, setSubmiting] = useState(false);

  const [tests, setTests] = useState<ITest[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get("/performance-report/tests");

      setTests(data.data);
      setUrl("");
    } catch (e) {
      toast({
        title: "Ops.",
        description: "Something wrong happened.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
    }
  }, [toast]);

  const setData = useCallback(async (): Promise<void> => {
    setLoading(true);
    await getData();
    setLoading(false);
  }, [getData]);

  useEffect(() => {
    setData();
  }, [setData]);

  const submit = async (): Promise<void> => {
    try {
      setSubmiting(true);
      await api.post("/performance-report/tests", { url });

      getData();
      setSubmiting(false);
    } catch (e) {
      toast({
        title: "Ops.",
        description: "Something wrong happened.",
        status: "error",
        duration: 9000,
        isClosable: true
      });
      setSubmiting(false);
    }
  };

  const hasTestRunning = useCallback((): boolean => {
    const runnings = tests.filter(test => test.links === undefined);

    return !!runnings.length;
  }, [tests]);

  useEffect(() => {
    if (hasTestRunning()) setTimeout(() => getData(), 10000);
  }, [tests, hasTestRunning, getData]);

  return (
    <DashboardTemplate>
      <Box mb="48px">
        <Heading fontSize="45px">
          <Text d="inline" color="#EA384D">
            GT
          </Text>
          Metrix
        </Heading>
        <Text>Run tests and watch your websites performances</Text>
      </Box>

      <HStack spacing="24px" mb="24px">
        <Heading as="h2" fontSize="28px">
          Reports
        </Heading>
        <Image src={Reports} />
      </HStack>

      <Flex flexDir={{ base: "column", lg: "row" }} mb="48px">
        <Input
          border="none"
          borderRadius="29px"
          bgColor="rgba(255,255,255,.1)"
          __css={{
            backdropFilter: "blur(18px)"
          }}
          value={url}
          onChange={e => setUrl(e.target.value)}
          h="54px"
          color="white"
          placeholder="Paste the URL"
          px="32px"
          _placeholder={{
            color: "#8B8B8B"
          }}
          onKeyPress={e => (e.key === "Enter" ? submit() : null)}
        />
        <Button
          colorScheme="red"
          onClick={submit}
          isLoading={submiting}
          h="54px"
          borderRadius="29px"
          px="40px"
          rightIcon={<BsChevronRight />}
          ml={{
            base: "0",
            lg: "24px"
          }}
          mt={{
            base: "16px",
            lg: "0px"
          }}
        >
          Run page test
        </Button>
      </Flex>

      {loading ? (
        <Box textAlign="center" py="64px">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="red.500"
            size="xl"
          />
          <Text>Loading reports</Text>
        </Box>
      ) : (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr 1fr",
            lg: "1fr 1fr 1fr 1fr"
          }}
          gap="16px"
        >
          {tests.map(test => (
            <PageCard test={test} key={test.id} />
          ))}
        </Grid>
      )}
    </DashboardTemplate>
  );
};

export default Home;
