import React from 'react';
import {
  Heading, HStack, Tooltip, IconButton, Box, Flex
} from '@chakra-ui/react';
import Metric from 'Components/elements/Metric';
import { FaQuestionCircle } from 'react-icons/fa';
import renderValue from 'Core/helpers/renderValue';

const Header: React.FC<{ title: string, help: string }> = function ({ title, help }) {
  return (
    <HStack mb="16px">
      <Heading
        as="h4"
        fontSize="15px"
        fontWeight="600"
        color="#BFBFBF"
      >
        {title}
      </Heading>
      <Tooltip
        label={help}
        fontSize="12px"
        borderRadius="5px"
        p="12px"
        bgColor="white"
        color="black"
      >
        <IconButton
          _hover={{
            color: 'red'
          }}
          icon={<FaQuestionCircle size="13px" />}
          aria-label="faq"
          size="xs"
          variant="ghost"
        />
      </Tooltip>
    </HStack>
  );
};

interface BrowserTimingsProps {
  items: {
    title: string,
    help: string,
    value: number
  }[]
}

const BrowserTimings: React.FC<BrowserTimingsProps> = function ({ items }) {
  return (
    <Metric
      title="Browser Timings"
      help="Helper text"
    >
      <Flex flexDir={{ base: 'column', lg: 'row' }} alignItems="center" justifyContent="space-between">
        {
          items.map((item) => (
            <Box key={item.title}>
              <Header title={item.title} help={item.help} />
              <Heading as="h5" fontSize="49px" fontWeight="700">
                {renderValue(item.value)}
              </Heading>
            </Box>
          ))
        }
      </Flex>
    </Metric>
  );
};

export default BrowserTimings;
