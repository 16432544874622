import React from 'react';
import IRoute from 'Core/types/IRoute';

import Home from 'Pages/Home';
import Test from 'Pages/Test';

const routes: IRoute[] = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/test/:id',
    element: <Test />
  }
];

export default routes;
